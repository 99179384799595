<template>
  <div id="ServicesPage" class="pb-8">
    <min-nav></min-nav>
    <page-header title=" احصائيات الحسابات" icon="fas fa-calendar-check"></page-header>
    <div class="container mx-auto p-4">
      <div class="input mb-4">
            <label for="" class="col-6"> تحديد تاريخ للصرفيات و الارباح</label>
            <date-picker
              @change="filtering()"
              v-model="filterData.date"
              valueType="format"
            ></date-picker>
          </div>
      <get-loader class="absolute inset-0"></get-loader>
      <div v-for="(group, index) in cardGroups" :key="index">
        <h2 class="text-2xl font-bold mb-4 text-right mt-5">
          <span v-if="index == 0">الصرفيات</span>
          <span v-if="index == 1">الارباح</span>
          <span v-if="index == 2">صافي الارباح</span>
        </h2>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <div
            v-for="card in group"
            :key="card.id"
            class="bg-white shadow-md rounded-lg p-3 flex flex-col"
          >
            <div class="flex justify-between">
              <h3 class="text-xl font-bold mb-2">{{ card.title }}</h3>
              <i :class="card.icon" class="text-2xl mb-4"></i>
            </div>
            <p dir="ltr" class="text-gray-700 text-4xl font-bold mt-8">
              {{ card.number }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
header {
  border-radius: 0 0 50% 50%;
}
</style>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  /* eslint-disable */
  components: { DatePicker },
  data() {
    return {
      cards: [
        { id: 1, title: "الصرفيات اليومية", icon: "fas fa-sun", number: "" },
        { id: 2, title: "الصرفيات الاسبوعية", icon: "fas fa-calendar-check", number: "" },
        { id: 3, title: "الصرفيات الشهرية", icon: "fas fa-calendar", number: "" },
        { id: 4, title: "الصرفيات السنوية", icon: "fas fa-list-alt", number: "" },
        { id: 5, title: "الارباح اليومية", icon: "fas fa-sun", number: "" },
        { id: 6, title: "الارباح الاسبوعية", icon: "fas fa-calendar-check", number: "" },
        { id: 7, title: "الارباح الشهرية", icon: "fas fa-calendar", number: "" },
        { id: 8, title: "الارباح السنوية", icon: "fas fa-list-alt", number: "" },
        { id: 9, title: "الصافي اليومي", icon: "fas fa-sun", number: "" },
        { id: 10, title: "الصافي الاسبوعي", icon: "fas fa-calendar-check", number: "" },
        { id: 11, title: "الصافي الشهري", icon: "fas fa-calendar", number: "" },
        { id: 12, title: "الصافي السنوي", icon: "fas fa-list-alt", number: "" },
      ],
      date: null,
      currentPage: 1,
      postRewardsData: {
        amount: "",
        employee_id: "",
        notes: "",
        date: "",
      },
      editData: {},
      filterData: {
        date:''
      },
    };
  },
  watch: {},
  computed: {
    cardGroups() {
      const groups = [];
      for (let i = 0; i < this.cards.length; i += 4) {
        groups.push(this.cards.slice(i, i + 4));
      }
      return groups;
    },
    filtering(){
    this.fetchData();
    }
  },
  methods: {
    async fetchData() {
      try {
        const expanseRes = await this.getExpanses(this.filterData);
        this.cards[0].number = expanseRes.data.daily;
        this.cards[1].number = expanseRes.data.weekly;
        this.cards[2].number = expanseRes.data.monthly;
        this.cards[3].number = expanseRes.data.yearly;

        const incomeRes = await this.getEncome(this.filterData);
        this.cards[4].number = incomeRes.data.daily;
        this.cards[5].number = incomeRes.data.weekly;
        this.cards[6].number = incomeRes.data.monthly;
        this.cards[7].number = incomeRes.data.yearly;

        // Calculations for net income
        this.cards[8].number = this.cards[4].number - this.cards[0].number;
        this.cards[9].number = this.cards[5].number - this.cards[1].number;
        this.cards[10].number = this.cards[6].number - this.cards[2].number;
        this.cards[11].number = this.cards[7].number - this.cards[3].number;

        // Formatting the numbers
        for (let i = 0; i < this.cards.length; i++) {
          this.cards[i].number = this.cards[i].number.toLocaleString();
        }
      } catch (error) {
        console.error("Error fetching data", error);
      }
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
.container {
  max-width: 1200px;
}
</style>
