<template>
  <div id="OrderPage" class="pb-8">
    <min-nav></min-nav>
    <page-header title="طلبات الصيانة" icon="fas fa-users"></page-header>
    <div :class="[editStatus ? 'active' : '', 'edit-form']">
      <form
        action=""
        class="grid grid-cols-2 lg:grid-cols-3 gap-1 w-11/12 md:w-8/12 bg-white rounded px-3 py-5 max-h-full overflow-auto"
      >
        <div class="input">
          <label for="" class=""> أسم السائق </label>
          <input
            type="text"
            placeholder="أسم السائق"
            v-model="editData.driver_name"
            class="field"
          />
        </div>
        <div class="input">
          <label for="" class="requier"> رقم الهاتف </label>
          <input
            type="text"
            placeholder="رقم الهاتف"
            v-model="editData.driver_phone"
            class="field"
          />
        </div>
        <div class="input mb-4">
          <label for="" class="requier"> نوع السيارة </label>
          <input
            type="text"
            placeholder="نوع السيارة"
            v-model="editData.car_name"
            class="field"
          />
        </div>
        <div class="input mb-4">
          <label for="" class="requier"> رقم السيارة </label>
          <input
            type="text"
            placeholder="رقم السيارة"
            v-model="editData.vehicle_no"
            class="field"
          />
        </div>

        <div class="input mb-4">
          <label for="" class="requier"> لون السيارة </label>
          <input
            type="text"
            placeholder="لون السيارة"
            v-model="editData.color"
            class="field"
          />
        </div>

        <div class="input mb-4">
          <label for="" class="requier"> موديل السيارة </label>
          <input
            type="text"
            placeholder="موديل السيارة"
            v-model="editData.model"
            class="field"
          />
        </div>

        <!-- <div class="input mb-4">
          <label for="" class="requier"> رقم الشاصي </label>
          <input type="text" placeholder="رقم الشاصي" v-model="editData.chassis_no" class="field" />
        </div> -->

        <div class="input mb-4">
          <label for="" class="requier"> السعر </label>
          <input type="text" placeholder="السعر" v-model="editData.price" class="field" />
        </div>

        <div class="input mb-4">
          <label for="" class="requier"> الملاحظات</label>
          <textarea
            rows="4"
            cols="4"
            placeholder="الملاحظات"
            v-model="editData.notes"
            class="field"
          >
          </textarea>
        </div>
        <div class="input mb-4">
          <label for="" class="requier">اختيار الموظف</label>
          <select name="" id="" v-model="editData.worker_id" class="field">
            <option :value="item.id" v-for="(item, index) in employees.data" :key="index">
              {{ item.name }}
            </option>
          </select>
        </div>

        <div class="controll flex justify-end pt-8 col-span-full">
          <button class="btn sub-btn" @click.prevent="toggleEditStatus()">غلق</button>
          <button
            :class="[postLoader ? 'opacity-60 cursor-not-allowed' : '', 'btn main-btn']"
            :disabled="postLoader"
            @click="edit_item()"
          >
            تعديل
          </button>
        </div>
      </form>
    </div>
    <div
      v-if="chackOwner || checkRole(['store-Maintenance-Order'])"
      class="add-form -mt-10 bg-white w-11/12 mx-auto py-4 rounded shadow mb-3"
    >
      <div class="container">
        <div class="section-title">
          <span class="text-xl">أضافة طلب</span>
        </div>
        <form action="" class="grid grid-cols-2 lg:grid-cols-4 gap-2">
          <div class="input">
            <label for="" class=""> أسم السائق </label>
            <input
              type="text"
              placeholder="أسم السائق"
              v-model="postData.driver_name"
              class="field"
            />
          </div>
          <div class="input">
            <label for="" class="requier"> رقم الهاتف </label>
            <input
              type="text"
              placeholder="رقم الهاتف"
              v-model="postData.driver_phone"
              class="field"
            />
          </div>
          <div class="input mb-4">
            <label for="" class="requier"> نوع السيارة </label>
            <input
              type="text"
              placeholder="نوع السيارة"
              v-model="postData.car_name"
              class="field"
            />
          </div>

          <div class="input mb-4">
            <label for="" class="requier"> رقم السيارة </label>
            <input
              type="text"
              placeholder="رقم السيارة"
              v-model="postData.vehicle_no"
              class="field"
            />
          </div>

          <div class="input mb-4">
            <label for="" class="requier"> لون السيارة </label>
            <input
              type="text"
              placeholder="لون السيارة"
              v-model="postData.color"
              class="field"
            />
          </div>

          <div class="input mb-4">
            <label for="" class="requier"> موديل السيارة </label>
            <input
              type="text"
              placeholder="موديل السيارة"
              v-model="postData.model"
              class="field"
            />
          </div>

          <!-- <div class="input mb-4">
            <label for="" class="requier"> رقم الشاصي </label>
            <div class="relative flex">
              <input type="search" placeholder="رقم الشاصي" class="field pr-16 rounded-r-none"
                v-model="postData.chassis_no" />
              <span @click="filteringChassie()"
                class="absolute inset-y-0 left-0 flex items-center px-2 text-gray-100 cursor-pointer bg-blue-500 rounded-l">
                <i class="fas fa-search"></i>
              </span>
            </div>
            <p class="relative flex" style="color: green; font-weight: 600;"
              v-if="postData.chassis_no && chassis_filter && chassis_result_count > 0">
              * تم فحصها ({{ chassis_result_count }}) مرة

            </p>
            <p style="color: red; font-weight: 600;"
              v-if="postData.chassis_no && chassis_filter && chassis_result_count === 0">
              * لم يتم فحصها سابقاً
            </p>
          </div> -->

          <div class="input mb-4">
            <label for="" class="requier"> قسم الفحص </label>
            <select name="" id="" v-model="postData.department_id" class="field">
              <option value="" selected disabled>القسم</option>
              <option
                v-for="(item, index) in departments.data"
                :value="item.id"
                v-bind:key="index"
              >
                {{ item.ar_name }}
              </option>
            </select>
          </div>

          <div class="input mb-4">
            <label for="" class="requier"> السعر </label>
            <input
              type="text"
              placeholder="السعر"
              v-model="postData.price"
              class="field"
            />
          </div>
          <div class="input mb-4">
            <label for="" class="requier">اختيار الموظف</label>
            <select name="" id="" v-model="postData.worker_id" class="field">
              <option
                :value="item.id"
                v-for="(item, index) in employees.data"
                :key="index"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
          <div class="input mb-4">
            <label for="" class=""> الملاحظات</label>
            <textarea
              rows="4"
              cols="4"
              placeholder="الملاحظات"
              v-model="postData.notes"
              class="field"
            >
            </textarea>
          </div>

          <div class="controll flex justify-end col-span-full">
            <button
              :class="[postLoader ? 'opacity-60 cursor-not-allowed' : '', 'btn main-btn']"
              :disabled="postLoader"
              @click.prevent="add_new_item()"
            >
              أضافة
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="list-form">
      <div class="container">
        <div class="section-title">
          <span class="text-xl">قائمة الطلبات</span>
          <div class="show-per-Page text-sm font-normal">
            <span class=""> عناصر القائمة </span>
            <select
              name=""
              id=""
              v-model="filterData.take"
              class="bg-gray-100 px-2 rounded-md outline-none"
              @change="filtering"
            >
              <option :value="2">2</option>
              <option :value="15">15</option>
              <option :value="50">50</option>
              <option :value="100">100</option>
              <option :value="500">500</option>
            </select>
          </div>
        </div>

        <div class="filters">
          <div class="name input">
            <input
              type="search"
              @change="filtering()"
              placeholder="اسم السائق"
              class="field"
              v-model="filterData.name"
            />
          </div>
          <div class="name input">
            <input
              type="search"
              @change="filtering()"
              placeholder="رقم السيارة"
              class="field"
              v-model="filterData.vehicle_no"
            />
          </div>
          <div class="input mb-4">
            <!-- <label for="" class="requier"> التاريخ</label> -->
            <date-picker
              @change="filtering()"
              v-model="filterData.created_at"
              valueType="format"
            ></date-picker>
          </div>
          <!-- <div class="name input">
            <input type="search" @change="filtering()" placeholder="رقم الشاصي" class="field"
              v-model="filterData.chassis_no" />
          </div> -->
          <!-- <div class="status input">
            <select name="" id="" class="field" @change="filterOrderStatus()" v-model="filterOrderStatusvalue">
              <option value="" selected disabled>حالة الطلب</option>
              <option value="">الكل</option>
              <option :value="5">ملغا</option>
              <option :value="4">مكتمل</option>
            </select>
          </div> -->
        </div>
        <div class="list relative py-5">
          <get-loader
            v-if="!chassis_result_count && !chassis_filter && !postData.chassis_no"
            class="absolute inset-0"
          ></get-loader>
          <data-table :fields="fields" :data="orders.data" :actions="false">
            <template v-slot:order_no="{ item }">
              {{ item.order_no?.split("-")[4] }}
            </template>
            <template v-slot:created_at="{ item }">
              {{ getFormDate(item.created_at) }}
            </template>
            <template v-slot:worker="{ item }">
              <span>
                {{ item.worker ? item.worker.name : "-" }}
              </span>
            </template>
            <template v-slot:department_id="{ item }">
              <span class="border px-2 py-1 rounded-md w-22 text-center block">
                {{ MaintenanceOrderStatus(item.department_id) }}
              </span>
            </template>
            <template v-slot:updated_at="{ item }">
              {{ getFormDate(item.updated_at) }}
            </template>
            <template v-slot:status="{ item }">
              <span v-if="!item.status" style="color: orange">قيد العمل</span>
              <span v-if="item.status == 1" style="color: green">مكتمل</span>
            </template>
            <template v-slot:actions="{ item }">
              <ul class="actions-list">
                <li
                  @click="add_img_form(item.id)"
                  aria-label="اضافة صور"
                  class="text-gray-500 hover:text-purple-500 hint--top"
                >
                  <i class="fas fa-file-image"></i>
                </li>
                <li
                  v-if="checkRole(['update-Maintenance-Order'])"
                  aria-label="تعديل الطلب"
                  class="text-gray-500 hover:text-blue-500 hint--top"
                  @click="editInit(item)"
                >
                  <i class="fas fa-pencil-alt"></i>
                </li>
                <li
                  v-if="checkRole(['update-Maintenance-Order'])"
                  aria-label="أكمال الطلب"
                  class="text-gray-500 hover:text-green-500 hint--top"
                  @click="complete_order(item)"
                >
                  <i class="fas fa-clipboard-check"></i>
                </li>
                <li
                  v-if="checkRole(['update-user'])"
                  aria-label="الغاء الطلب"
                  class="text-gray-500 hover:text-red-500 hint--top"
                  @click="delete_order(item)"
                >
                  <i class="fas fa-times"></i>
                </li>
              </ul>
            </template>
          </data-table>
        </div>
      </div>
    </div>
    <div
      class="pagination w-11/12 mx-auto py-4 bg-white mt-3"
      v-if="orders.total > filterData.take"
    >
      <div class="container flex items-center justify-center">
        <v-pagination
          v-model="currentPage"
          :records="orders.total"
          :per-page="filterData.take"
        />
      </div>
    </div>
  </div>
</template>
<style>
header {
  border-radius: 0 0 50% 50%;
}
</style>
<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import $http from "@/plugins/axios.js";
export default {
  /* eslint-disable */
  components: { DatePicker },
  data() {
    return {
      currentPage: 1,
      changeStatusInit: false,
      filterOrderStatusvalue: "",
      carTypes: ["Kia", "Hunday", "Tayouta"],
      postData: {
        driver_name: "",
        driver_phone: "",
        car_no: "",
        car_type: "",
        car_color: "",
        car_body_type: "",
        car_model: "",
        sanoia_no: "",
        chassis_no: "",
        speedometer: "",
        price: "",
        discount: 0,
        status: "",
        worker_id: "",
      },
      changeStatusValue: {
        status: "",
      },
      editData: {},
      tempData: {},
      filterData: {
        take: 15,
        page: 1,
        name: "",
        vehicle_no: "",
        chassis_no: "",
        sanoia_no: "",
        created_at: "",
      },
      chassis_filter: false,
      chassis_result_count: 0,
    };
  },
  computed: {
    fields() {
      return [
        {
          title: "أسم السائق",
          key: "driver_name",
        },
        {
          title: "رقم السائق",
          key: "driver_phone",
        },
        {
          title: "نوع السيارة",
          key: "car_name",
        },
        {
          title: "لون السيارة",
          key: "color",
        },
        {
          title: "رقم السيارة",
          key: "vehicle_no",
        },
        {
          title: "السعر",
          key: "price",
        },
        {
          title: "اسم العامل",
          key: "worker",
        },
        {
          title: "الحالة",
          key: "status",
        },
        {
          title: "تاريخ",
          key: "created_at",
        },
        {
          title: "قسم الفحص",
          key: "department_id",
        },
        {
          title: "تعديل",
          key: "actions",
        },
      ];
    },
    chackOwner() {
      if (this.getUserRole == "Owner") {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    currentPage(value) {
      this.filterData.page = value;
      this.getMaintenanceOrders(this.filterData);
    },
  },
  methods: {
    filtering() {
      this.getMaintenanceOrders(this.filterData);
    },
    filteringChassie() {
      $http
        .get(`/orders-today?filter[chassis_no]=${this.postData.chassis_no}&take=1000`)
        .then((res) => {
          this.chassis_result_count = res.data.total;
          this.chassis_filter = true;
        });
    },
    filterOrderStatus() {
      if (this.filterOrderStatusvalue == "") {
        this.getMaintenanceOrders();
      }
      // else if (this.filterOrderStatusvalue == 4) {
      //   this.getCompletedOrders();
      // } else {
      //   this.getCancelleddOrders();
      // }
    },
    add_new_item() {
      this.createMaintenanceOrder(this.postData).then(() => {
        this.postData = {
          driver_name: "",
          driver_phone: "",
          vehicle_no: "",
          car_name: "",
          color: "",
          model: "",
          price: "",
          notes: "",
          department_id: 1,
        };
      });
      this.orders.data = [];
      this.getMaintenanceOrders(this.filterData)
    },
    editInit(item) {
      this.toggleEditStatus();
      Object.assign(this.editData, item);
      this.editData._method = "PUT";
    },
    edit_item() {
      delete this.editData.last_login;
      this.updateMaintenanceOrder(this.editData).then(() => {
        this.toggleEditStatus();
        this.getMaintenanceOrders();
      });
    },
    add_img_form(id) {
      this.$router.push(`/maintenance_orders_image/${id}`);
    },
    delete_order(item) {
      this.confirmDelete(() => {
        this.deleteOrder(item.id);
      });
    },
    complete_order(item) {
      this.confirmDelete(() => {
        this.completeMaintenanceOrder(item.id).then(() =>
          this.getMaintenanceOrders(this.filterData)
        );
      });
    },
    showChangeStatus(item) {
      this.changeStatusInit = true;
      this.changeStatusValue.order_id = item.id;
    },
    changeStatusData() {
      this.changeStatusOrder({
        order_id: this.changeStatusValue.order_id,
        data: this.dataReutrn({
          status: this.changeStatusValue.status,
          _method: "PUT",
        }),
      }).then(() => {
        this.changeStatusInit = false;
        this.getMaintenanceOrders();
      });
    },
  },
  mounted() {
    this.getMaintenanceOrders();
    this.getDepartments();
    this.getEmployees();
    document.querySelectorAll("#OrderPage .field").forEach((el) => {
      el.addEventListener("input", () => {
        if (el.value.length !== 0) {
          el.classList.add("filled");
        } else {
          el.classList.remove("filled");
        }
      });
    });
  },
};
</script>
