import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


Vue.config.productionTip = false

import Swal from 'sweetalert2'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import Data_Table from "./components/Globle/DataTable.vue"
import Pagination from 'vue-pagination-2';
import _min_nav from "./components/Globle/_min_nav.vue"
import _header_page from "./components/Globle/_header_page.vue"
import _get_loader from "./components/Globle/_get_loader.vue"
import vSelect from 'vue-select'
import moment from "moment"
import VueQrcode from '@chenfengyuan/vue-qrcode';

import 'vue-select/dist/vue-select.css';
import 'sweetalert2/dist/sweetalert2.min.css'
import 'hint.css'
import '@/assets/styles/style.css'

var mixin = {
    data() {
        return {
            baseUrl: 'http://162.214.159.53:8020/api/',
            alertMsg: "You won't be able to revert this!"
        }
    },
    computed: {
        ...mapGetters([
            'getLoader',
            'postLoader',
            'editStatus',
            'sideBarStatus',
            'user_permissions',
            'users',
            'userInfo',
            'orders',
            'order',
            'daions',
            'computer',
            'jeks',
            'covers',
            'permissions',
            'roles',
            'role',
            'employees',
            'departments',
            'employee',
            'employees_salary',
            'punishments',
            'financials',
            'rewards',
            'loans',
            'loan',
            'salary',
            'loans_repayment',
            'expanses',
            'encome'
        ]),
        getUserName() {
            return JSON.parse(localStorage.getItem('il_user_Info'))?.full_name || false
        },
        getUserEmail() {
            return JSON.parse(localStorage.getItem('il_user_Info'))?.email || false
        },
        getUserRole() {
            return JSON.parse(localStorage.getItem('car_user_Info'))?.userAuth.roles[0]?.name || false
        },
        getUserPermissions() {
            return JSON.parse(localStorage.getItem('car_user_permissions'))
        }
    },
    methods: {
        ...mapMutations(['toggleEditStatus', 'toggleSideBarStatus']),
        ...mapActions([
            'login',
            'logOut',

            'getUsres',
            'createUser',
            'updateUser',
            'removeUser',

            'getOrders',
            'getHabibiyaOrders',
            'getHabibiyaMaintenanceOrders',
            'getHabibiyaFinancials',
            'deletePunishments',
            'deleteFinancials',
            'backToLoan',
            'deleteRewards',
            'deleteLoans',
            'getEmployees',
            'getEmployeesData',
            'getExpanses',
            'getEncome',
            'createSalary',
            'changeEmployeeSalary',
            'getPunishments',
            'getFinancials',
            'getLoans',
            'getSalary',
            'createPunishments',
            'createLoansRepayments',
            'getLoansRepayments',
            'createFinancials',
            'createLoans',
            'getDepartments',

            'getRewards',
            'createRewards',
            'getLoansId',
            'getDayleOrders',
            'getUsers',
            'getOrder',
            'getOrderMaintenance',
            'createMaintenanceOrderImage',
            'uploadMaintenanceImage',
            'removeMaintenanceImage',
            'updateMaintenanceOrder',
            'deleteOrder',
            'getPublicOrder',
            'completeMaintenanceOrder',
            'createMaintenanceOrder',
            'getMaintenanceOrders',

            'createOrder',
            'createOrderImage',
            'updateOrder',
            'getCompletedOrders',
            'getCancelleddOrders',
            'completeOrder',
            'canceleOrder',
            'changeStatusOrder',
            'uploadImage',
            'removeImage',

            'getJeks',
            'getJek',
            'createJek',
            'updateJek',
            'removeJek',

            'getDaions',
            'createDaion',
            'updateDaion',
            'removeDaion',

            'getComputers',
            'createComputer',
            'updateComputer',
            'removeComputer',

            'getCovers',
            'createCover',
            'updateCover',
            'removeCover',



            'getPermissions',
            'createPermission',
            'updatePermission',
            'removePermission',

            'getRoles',
            'showRole',
            'createRole',
            'updateRole',
            'removeRole',
            'assignRole',

        ]),
        orderStatus(status) {
            const statuses = {
                0: {
                    text: 'Pending',
                    cla: 'pending'
                },
                1: {
                    text: 'Jek',
                    cla: 'jek'
                },
                2: {
                    text: 'Daino',
                    cla: 'daino'
                },
                3: {
                    text: 'Cover',
                    cla: 'cover'
                },
                4: {
                    text: 'Completed',
                    cla: 'completed'
                },
                5: {
                    text: 'Cancelled',
                    cla: 'cancelled'
                },
                6: {
                    text: 'Computer',
                    cla: 'comp'
                },
            }
            return statuses[status]
        },
        MaintenanceOrderStatus(status) {
            for (let item in this.departments.data) {
                if (status == this.departments.data[item].id) {
                    return this.departments.data[item].ar_name
                }
            }
        },
        coverStatus(status) {
            const statuses = {
                0: {
                    text: 'سليم',
                },
                1: {
                    text: 'مستبدل بلادي',
                },
                2: {
                    text: 'القطعة مفقودة',
                },
                3: {
                    text: 'مستبدل مصبوغ',
                },
                4: {
                    text: 'بارد',
                },
                5: {
                    text: 'قطعة مصبوغة',
                },
                6: {
                    text: 'معجون',
                },
                7: {
                    text: 'تكحيل',
                },
                8: {
                    text: 'كلير',
                },
                9: {
                    text: 'شخوط',
                },
                10: {
                    text: 'رصعة',
                },
                11: {
                    text: 'جراد',
                },
            }
            return statuses[status]
        },
        checkRole(role) {
            if (role.length == 0) {
                return true
            } else {
                return JSON.parse(localStorage.getItem('car_user_permissions')).some(item => role.includes(item))
            }
        },
        scrollToElemnt(el) {
            window.scrollTo({
                top: document.querySelector("." + el).offsetTop,
                behavior: "smooth"
            })
        },
        getFormDate(value) {
            moment.locale('ar');
            return moment(value).format('h:mm a YYYY/MM/DD ')
        },
        dataReutrn(obj) {

            var data = new FormData();

            for (var key in obj) {
                data.append(key, obj[key])
            }

            return data

        },
        getImg(event, obj, key, view_key) {
            this[view_key] = URL.createObjectURL(event.target.files[0])
            this[obj][key] = event.target.files[0]
        },
        confirmDelete(doing) {
            Swal.fire({
                title: 'تأكيد العملية',
                text: "لايمكن التراجع بعد هذه الخطوة",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'أكمل',
                cancelButtonText: 'الغاء'
            }).then((result) => {
                if (result.isConfirmed) {
                    doing()
                }
            })
        }
    },
}

Vue.component('data-table', Data_Table)
Vue.component('v-pagination', Pagination);
Vue.component('min-nav', _min_nav)
Vue.component('page-header', _header_page)
Vue.component('get-loader', _get_loader)
Vue.component('v-select', vSelect)
Vue.component(VueQrcode.name, VueQrcode);
Vue.mixin(mixin)

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
