<template>
  <div id="ServicesPage" class="pb-8">
    <min-nav></min-nav>
    <page-header title="جـــك" icon="fas fa-cogs"></page-header>
    <div
      v-if="checkRole(['store-Jek'])"
      class="add-form -mt-10 bg-white w-11/12 mx-auto py-4 rounded shadow mb-3"
    >
      <div class="container">
        <div class="section-title">
          <span class="text-xl">فحص الجـــك</span>
        </div>
        <form action="" class="grid grid-cols-2 lg:grid-cols-3 gap-3 relative">
          <get-loader class="absolute inset-0 z-10"></get-loader>
          <div class="input">
            <label for="" class="requier"> شمعات صدر</label>
            <input
              type="text"
              placeholder="شمعات صدر "
              v-model="postData.sham3at_sadder"
              class="field"
            />
          </div>
          <div class="input">
            <label for="" class="requier"> بوَشات </label>
            <input
              type="text"
              placeholder="بوشات"
              v-model="postData.bushes"
              class="field"
            />
          </div>
          <div class="input">
            <label for="" class="requier"> كيس الكير مفتوح؟ </label>
            <select name="" id="" v-model="postData.rabaat" class="field">
              <option value="" selected disabled>كيس الكير</option>
              <option value="نعم">نعم</option>
              <option value="لا">لا</option>
            </select>
          </div>
          <div class="input">
            <label for="" class="requier"> راس رواط </label>
            <input
              type="text"
              placeholder=" راس رواط "
              v-model="postData.head_of_roat"
              class="field"
            />
          </div>
          <div class="input">
            <label for="" class="requier"> طبلة </label>
            <input
              type="text"
              placeholder=" طبلة "
              v-model="postData.tabla"
              class="field"
            />
          </div>
          <div class="input mb-4">
            <label for="" class="requier"> التبريد </label>
            <select name="" id="" v-model="postData.cooling" class="field">
              <option value="" selected disabled>التبريد</option>
              <option value="جيد">جيد</option>
              <option value="متوسط">متوسط</option>
              <option value="رديء">رديء</option>
            </select>
          </div>
          <div class="input">
            <label for="" class="requier"> المحرك مفتوح ؟</label>
            <input
              type="checkbox"
              class="w-6 h-6"
              value="1"
              v-model="postData.is_engine_open"
            />
          </div>
          <div class="input">
            <label for="" class="requier"> صوت في المحرك ؟ </label>
            <input type="checkbox" class="w-6 h-6" v-model="postData.sound_in_engine" />
          </div>
          <div class="input">
            <label for="" class="requier"> نضوح زيت في المحرك ؟ </label>
            <input
              type="checkbox"
              class="w-6 h-6"
              v-model="postData.oil_oozes_from_engine"
            />
          </div>
          <div class="input">
            <label for="" class="requier"> نضوح زيت في الكير ؟ </label>
            <input
              type="checkbox"
              class="w-6 h-6"
              v-model="postData.leaking_oil_from_gear"
            />
          </div>
          <div class="input">
            <label for="" class="requier"> نضوح ماء ؟ </label>
            <input type="checkbox" class="w-6 h-6" v-model="postData.leaking_water" />
          </div>
          <div class="input">
            <label for="" class="requier"> كيس المحرك مفتوح ؟ </label>
            <input
              type="checkbox"
              class="w-6 h-6"
              v-model="postData.is_kais_engine_open"
            />
          </div>
          <div class="input">
            <label for="" class="requier"> تايمن كيس مفتوح ؟ </label>
            <input
              type="checkbox"
              class="w-6 h-6"
              v-model="postData.is_taiman_kais_engine_open"
            />
          </div>
          <div class="input">
            <label for="" class="requier"> خشونة في المحرك ؟ </label>
            <input
              type="checkbox"
              class="w-6 h-6"
              value="false"
              v-model="postData.roughness_engine"
            />
          </div>
          <div class="input mb-4 col-span-full">
            <label for="" class="requier"> وصف الحالة </label>
            <textarea
              placeholder="وصــف ...."
              v-model="postData.description"
              rows="6"
              class="field"
            ></textarea>
          </div>

          <div class="controll flex justify-end col-span-full">
            <button
              :class="[postLoader ? 'opacity-60 cursor-not-allowed' : '', 'btn main-btn']"
              :disabled="postLoader"
              @click="add_new_item()"
            >
              <span v-if="typeRequest == 'post'"> اضافة </span>
              <span v-else> تعديل </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<style>
header {
  border-radius: 0 0 50% 50%;
}
</style>
<script>
export default {
  data() {
    return {
      typeRequest: "post",
      postData: {
        order_id: "",
        sham3at_sadder: "",
        bushes: "",
        rabaat: "",
        head_of_roat: "",
        tabla: "",
        is_engine_open: false,
        sound_in_engine: false,
        oil_oozes_from_engine: false,
        description: "",
        is_taiman_kais_engine_open: false,
        is_kais_engine_open: false,
        roughness_engine: false,
        leaking_water: false,
        leaking_oil_from_gear: false,
        cooling: "",
      },
    };
  },
  methods: {
    add_new_item() {
      if (this.typeRequest == "post") {
        this.createJek(this.postData).then(() => {
          this.$router.push("/jek-list");
        });
      } else {
        this.updateJek(this.postData).then(() => {
          this.$router.push("/jek-list");
        });
      }
    },
  },
  mounted() {
    this.postData.order_id = this.$route.params.id;
    this.getOrder({
      id: this.postData.order_id,
      include: "jek",
    }).then(() => {
      if (this.order.jek) {
        this.typeRequest = "edit";
        this.postData = this.order.jek;
        this.postData._method = "PUT";
      }
    });
  },
};
</script>
