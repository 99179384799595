<template>
  <div id="ArchiveOrderPage" class="pb-8">
    <min-nav></min-nav>
    <page-header title="أرشيف الطلبات" icon="fas fa-archive"></page-header>
    <div
      v-if="checkRole(['change-order-status'])"
      :class="[changeStatusInit ? 'active' : '', 'edit-form']"
    >
      <form
        action=""
        class="grid grid-cols-1 gap-1 w-11/12 md:w-8/12 bg-white rounded px-8 py-5 max-h-full overflow-auto"
      >
        <div class="input mb-4">
          <label for="" class="requier"> قسم الفحص </label>
          <select name="" id="" v-model="changeStatusValue.status" class="field">
            <option value="" selected disabled>القسم</option>
            <option value="1">جـــك</option>
            <option value="2">داينو</option>
            <option value="3">فحص البدي</option>
            <option value="6">كمبيوتر</option>
          </select>
        </div>

        <div class="controll flex justify-end pt-8">
          <button class="btn sub-btn" @click.prevent="changeStatusInit = false">
            غلق
          </button>
          <button
            :class="[postLoader ? 'opacity-60 cursor-not-allowed' : '', 'btn main-btn']"
            :disabled="postLoader"
            @click.prevent="changeStatusData()"
          >
            تعديل الحالة
          </button>
        </div>
      </form>
    </div>
    <div
      v-if="checkRole(['update-Orders'])"
      :class="[editStatus ? 'active' : '', 'edit-form']"
    >
      <form
        action=""
        class="grid grid-cols-2 lg:grid-cols-3 gap-1 w-11/12 md:w-8/12 bg-white rounded px-3 py-5 max-h-full overflow-auto"
      >
        <div class="input">
          <label for="" class=""> أسم السائق </label>
          <input
            type="text"
            placeholder="أسم السائق"
            v-model="editData.driver_name"
            class="field"
          />
        </div>
        <div class="input">
          <label for="" class="requier"> رقم الهاتف </label>
          <input
            type="text"
            placeholder="رقم الهاتف"
            v-model="editData.driver_phone"
            class="field"
          />
        </div>
        <div class="input mb-4">
          <label for="" class="requier"> رقم السيارة </label>
          <input
            type="text"
            placeholder="رقم السيارة"
            v-model="editData.car_no"
            class="field"
          />
        </div>

        <div class="input mb-4">
          <label for="" class="requier"> نوع السيارة </label>
          <input
            type="text"
            placeholder="نوع السيارة"
            v-model="editData.car_type"
            class="field"
          />
        </div>

        <div class="input mb-4">
          <label for="" class="requier"> حجم السيارة </label>
          <select name="" id="" v-model="editData.car_body_type" class="field">
            <option value="" selected disabled>حجم السيارة</option>
            <option value="0">صالون</option>
            <option value="1">بيكم</option>
            <option value="2">فان</option>
            <option value="3">SUV</option>
          </select>
        </div>

        <div class="input mb-4">
          <label for="" class="requier"> لون السيارة </label>
          <input
            type="text"
            placeholder="لون السيارة"
            v-model="editData.car_color"
            class="field"
          />
        </div>

        <div class="input mb-4">
          <label for="" class="requier"> موديل السيارة </label>
          <input
            type="text"
            placeholder="موديل السيارة"
            v-model="editData.car_model"
            class="field"
          />
        </div>
        <div class="input mb-4">
          <label for="" class="requier"> رقم السنوية </label>
          <input
            type="text"
            placeholder="رقم السنوية"
            v-model="editData.sanoia_no"
            class="field"
          />
        </div>
        <div class="input mb-4">
          <label for="" class="requier"> رقم الشاصي </label>
          <input
            type="text"
            placeholder="رقم الشاصي"
            v-model="editData.chassis_no"
            class="field"
          />
        </div>

        <div class="input mb-4">
          <label for="" class="requier"> عداد السرعة </label>
          <input
            type="text"
            placeholder="عداد السرعة"
            v-model="editData.speedometer"
            class="field"
          />
        </div>

        <div class="input mb-4">
          <label for="" class="requier"> السعر </label>
          <input type="text" placeholder="السعر" v-model="editData.price" class="field" />
        </div>

        <div class="input mb-4">
          <label for="" class="requier"> الخصم </label>
          <input
            type="text"
            placeholder="الخصم"
            v-model="editData.discount"
            class="field"
          />
        </div>

        <div class="controll flex justify-end pt-8 col-span-full">
          <button class="btn sub-btn" @click.prevent="toggleEditStatus()">غلق</button>
          <button
            :class="[postLoader ? 'opacity-60 cursor-not-allowed' : '', 'btn main-btn']"
            :disabled="postLoader"
            @click="edit_item()"
          >
            تعديل
          </button>
        </div>
      </form>
    </div>
    <div class="list-form" v-if="checkRole(['get-Orders'])">
      <div class="container">
        <div class="section-title">
          <span class="text-xl">قائمة الطلبات</span>
          <div class="show-per-Page text-sm font-normal">
            <span class=""> عناصر القائمة </span>
            <select
              name=""
              id=""
              v-model="filterData.take"
              class="bg-gray-100 px-2 rounded-md outline-none"
              @change="filtering"
            >
              <option :value="2">2</option>
              <option :value="15">15</option>
              <option :value="50">50</option>
              <option :value="100">100</option>
              <option :value="500">500</option>
            </select>
          </div>
        </div>

        <div class="filters">
          <div class="name input">
            <input
              type="search"
              @change="filtering()"
              placeholder="اسم السائق"
              class="field"
              v-model="filterData.name"
            />
          </div>
          <div class="name input">
            <input
              type="search"
              @change="filtering()"
              placeholder="رقم السنوية"
              class="field"
              v-model="filterData.sanoia_no"
            />
          </div>
          <div class="name input">
            <input
              type="search"
              @change="filtering()"
              placeholder="رقم الشاصي"
              class="field"
              v-model="filterData.chassis_no"
            />
          </div>
          <div class="status input">
            <select
              name=""
              id=""
              class="field"
              @change="filterOrderStatus()"
              v-model="filterOrderStatusvalue"
            >
              <option value="" selected disabled>حالة الطلب</option>
              <option value="">الكل</option>
              <option :value="5">ملغا</option>
              <option :value="4">مكتمل</option>
            </select>
          </div>
          <div v-if="chackOwner || checkRole(['get-user'])" class="status input">
            <select
              name=""
              id=""
              class="field"
              @change="filtering()"
              v-model="filterData.creator_id"
            >
              <option value="" selected disabled>اسم المستخدم</option>
              <option :value="item.id" v-for="(item, index) in usersList" :key="index">
                {{ item.name }}
              </option>
            </select>
          </div>
          <div class="input mb-4">
            <!-- <label for="" class="requier"> التاريخ</label> -->
            <date-picker
              @change="filtering()"
              v-model="filterData.date"
              valueType="format"
              range
            ></date-picker>
          </div>
        </div>
        <div class="list relative py-5">
          <get-loader class="absolute inset-0"></get-loader>
          <data-table :fields="fields" :data="orders.data">
            <template v-slot:order_no="{ item }">
              {{ item.order_no?.split("-")[4] }}
            </template>
            <template v-slot:created_at="{ item }">
              {{ getFormDate(item.created_at) }}
            </template>
            <template v-slot:status="{ item }">
              <span
                :class="orderStatus(item.status).cla"
                class="border px-2 py-1 rounded-md w-22 text-center block"
              >
                {{ orderStatus(item.status).text }}
              </span>
            </template>
            <template v-slot:updated_at="{ item }">
              {{ getFormDate(item.updated_at) }}
            </template>
            <template v-slot:actions="{ item }">
              <ul class="actions-list">
                <li
                  v-if="checkRole(['change-order-status'])"
                  @click="showChangeStatus(item)"
                  aria-label="نقل الى قسم"
                  class="text-gray-500 hover:text-purple-500 hint--top"
                >
                  <i class="fas fa-bezier-curve"></i>
                </li>
                <li
                  v-if="checkRole(['store-OrderImage', 'get-OrderImage'])"
                  @click="add_img_form(item.id)"
                  aria-label="اضافة صور"
                  class="text-gray-500 hover:text-purple-500 hint--top"
                >
                  <i class="fas fa-file-image"></i>
                </li>
                <li
                  aria-label="طباعة فاتورة"
                  class="text-gray-500 hover:text-purple-500 hint--top"
                >
                  <router-link :to="'/print/' + item.id">
                    <i class="fas fa-print"></i>
                  </router-link>
                </li>
                <li
                  v-if="item.status <= 3 && checkRole(['update-Orders'])"
                  aria-label="تعديل الطلب"
                  class="text-gray-500 hover:text-blue-500 hint--top"
                  @click="editInit(item)"
                >
                  <i class="fas fa-pencil-alt"></i>
                </li>
                <li
                  v-if="item.status <= 3 && checkRole(['completed-Order'])"
                  aria-label="أكمال الطلب"
                  class="text-gray-500 hover:text-green-500 hint--top"
                  @click="complete_order(item)"
                >
                  <i class="fas fa-clipboard-check"></i>
                </li>
                <li
                  v-if="item.status <= 3 && checkRole(['cancelled-Order'])"
                  aria-label="الغاء الطلب"
                  class="text-gray-500 hover:text-red-500 hint--top"
                  @click="cancele_order(item)"
                >
                  <i class="fas fa-times"></i>
                </li>
                <!-- <li
                    class="text-gray-500 hover:text-red-500"
                    @click="remove_item(item)"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </li> -->
              </ul>
            </template>
          </data-table>
        </div>
      </div>
    </div>
    <div
      class="pagination w-11/12 mx-auto py-4 bg-white mt-3"
      v-if="orders.total > filterData.take"
    >
      <div class="container flex items-center justify-center">
        <v-pagination
          v-model="currentPage"
          :records="orders.total"
          :per-page="filterData.take"
        />
      </div>
    </div>
  </div>
</template>
<style>
header {
  border-radius: 0 0 50% 50%;
}
</style>
<script>
import DatePicker from "vue2-datepicker";
export default {
  components: { DatePicker },
  data() {
    return {
      currentPage: 1,
      changeStatusInit: false,
      filterOrderStatusvalue: "",
      carTypes: ["Kia", "Hunday", "Tayouta"],
      postData: {
        driver_name: "",
        driver_phone: "",
        car_no: "",
        car_type: "",
        car_color: "",
        car_body_type: "",
        car_model: "",
        sanoia_no: "",
        chassis_no: "",
        speedometer: "",
        price: "",
        discount: 0,
        status: "",
      },
      changeStatusValue: {
        status: "",
      },
      editData: {},
      tempData: {},
      filterData: {
        take: 15,
        name: "",
        chassis_no: "",
        sanoia_no: "",
        creator_id: "",
        number: "",
        date: "",
        date_from: "",
        date_to: "",
      },
      usersList: [],
      chassis_filter: false,
      chassis_result_count: "",
    };
  },
  computed: {
    fields() {
      return [
        {
          title: "رقم الفاتورة",
          key: "order_no",
        },
        {
          title: "تاريخ",
          key: "created_at",
        },
        {
          title: "أسم السائق",
          key: "driver_name",
        },
        {
          title: "رقم السيارة",
          key: "car_no",
        },
        {
          title: "نوع السيارة",
          key: "car_type",
        },
        {
          title: "لون السيارة",
          key: "car_color",
        },
        {
          title: "السعر",
          key: "price",
        },
        {
          title: "خصم",
          key: "discount",
        },
        {
          title: "السعر الكلي",
          key: "total_price",
        },
        {
          title: "حالة الطلب",
          key: "status",
        },
        {
          title: "أخر تعديل",
          key: "updated_at",
        },
      ];
    },
    chackOwner() {
      if (this.getUserRole == "Owner") {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    currentPage(value) {
      this.filterData.page = value;
      this.getOrders(this.filterData);
    },
  },
  methods: {
    filtering() {
      if (this.filterData.date.length) {
        this.filterData.date_from = this.filterData.date[0];
        this.filterData.date_to = this.filterData.date[1];
      }
      this.getOrders(this.filterData).then(() => {
        this.chassis_result_count = this.orders.total;
        this.chassis_filter = true;
      });
    },
    filterOrderStatus() {
      if (this.filterOrderStatusvalue == "") {
        this.getOrders(this.filterData);
      } else if (this.filterOrderStatusvalue == 4) {
        this.getCompletedOrders(this.filterData);
      } else {
        this.getCancelleddOrders(this.filterData);
      }
    },
    add_new_item() {
      this.createOrder(this.postData).then(() => {
        this.postData = {
          driver_name: "",
          driver_phone: "",
          car_no: "",
          car_type: "",
          car_body_type: "",
          car_color: "",
          car_model: "",
          sanoia_no: "",
          chassis_no: "",
          speedometer: "",
          price: "",
          discount: 0,
          status: 1,
        };
      });
    },
    editInit(item) {
      this.toggleEditStatus();
      Object.assign(this.editData, item);
      this.editData._method = "PUT";
    },
    edit_item() {
      delete this.editData.last_login;
      this.updateOrder(this.editData).then(() => {
        this.toggleEditStatus();
      });
    },
    add_img_form(id) {
      this.$router.push(`/order_image/${id}`);
    },
    cancele_order(item) {
      this.confirmDelete(() => {
        this.canceleOrder(item.id);
      });
    },
    complete_order(item) {
      this.confirmDelete(() => {
        this.completeOrder(item.id);
      });
    },
    showChangeStatus(item) {
      this.changeStatusInit = true;
      this.changeStatusValue.order_id = item.id;
    },
    changeStatusData() {
      this.changeStatusOrder({
        order_id: this.changeStatusValue.order_id,
        data: this.dataReutrn({
          status: this.changeStatusValue.status,
          _method: "PUT",
        }),
      }).then(() => {
        this.changeStatusInit = false;
        this.getOrders();
      });
    },
  },
  mounted() {
    this.getOrders();
    if (this.chackOwner) {
      this.getUsers().then(() => {
        this.usersList = this.users.data;
      });
    }
  },
};
</script>
