<template>
  <div id="printPage" class="w-10/12 bg-white mx-auto box-border relative">
    <get-loader class="absolute inset-0 z-10"></get-loader>
    <div
      class="print-btn w-16 h-16 rounded-full bg-main text-white text-2xl flex items-center justify-center fixed bottom-5 left-5 print:hidden cursor-default animate-bounce"
      @click="printPage()"
    >
      <i class="fas fa-print"></i>
    </div>
    <div
      class="water_mark fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 opacity-20 hidden print:block"
    >
      <img src="@/assets/logo_empty.png" class="w-60 z-40" alt="" />
    </div>
    <div class="order-container print:h-screen relative">

      <div class="container">
        <div
    class="invoice-header flex items-center justify-between py-2 z-20 border-t-8 print:border-t-0 border-gray-500 text-sm relative"
  >
    <div
      class="order_no py-1 px-3 text-sm bg-gray-500 absolute top-0 left-1/1 -translate-x-1/1 text-white font-bold transform skew-x-12"
    >
      {{ order.order_no }}
    </div>
    <div class="container flex items-center justify-between">
      <div class="order-main-info pt-4">
        <ul class="grid grid-cols-1 gap-x-8 gap-y-1 text-sm">
          <li>
            <span class="font-bold"> وقت الدخول : </span>
            <span>
              {{ getFormDate(order.created_at, "DD/MM/YYYY") }}
            </span>
          </li>
          <li>
            <span class="font-bold"> وقت الخروج : </span>
            <span>
              {{ getFormDate(order.updated_at) }}
            </span>
          </li>
        </ul>
      </div>
      <div class="logo">
        <img src="@/assets/logo_empty.png" class="rounded-md w-36" alt="" />
      </div>
    </div>
  </div>
      <div class="section-info">
        <div class="pt-8 mb-8">
          <div class="rounded-md p-2 border border-gray-300 relative text-sm">
            <div
              class="title font-bold mb-5 absolute -top-3 px-5 right-5 bg-indigo-50"
            >
              تفاصيل الطلب
            </div>
            <div class="header grid grid-cols-2">
              <ul class="">
                <li class="border-b pb-2 font-bold text-sm text-gray-500">
                  أسم السائق
                </li>
                <li class="border-b pb-2 font-bold text-sm text-gray-500">
                  رقم الهاتف
                </li>
                <li class="border-b pb-2 font-bold text-sm text-gray-500">
                  اسم السياره
                </li>
                <li class="border-b pb-2 font-bold text-sm text-gray-500">
                  رقم السياره
                </li>
                <li class="border-b pb-2 font-bold text-sm text-gray-500">
                  السعر
                </li>
                <li class="border-b pb-2 font-bold text-sm text-gray-500">
                  الملاحضات
                </li>
              </ul>
              <ul class="">
                <li class="border-b pb-2 font-bold text-sm text-gray-500">
                  {{ order.driver_name }}
                </li>
                <li class="border-b pb-2 font-bold text-sm text-gray-500">
                  {{ order.driver_phone }}
                </li>
                <li class="border-b pb-2 font-bold text-sm text-gray-500">
                  {{ order.car_name }}
                </li>
                <li class="border-b pb-2 font-bold text-sm text-gray-500">
                  {{ order.vehicle_no }}
                </li>
                <li class="border-b pb-2 font-bold text-sm text-gray-500">
                  {{ order.price }}
                </li>
                <li class="border-b pb-2 font-bold text-sm text-gray-500">
                  {{ order.notes }}
                </li>
              </ul>
            </div>
          </div>
          <span class="py-3 font-bold text-md inline-block">
            يخلي المركز مسؤوليته من نتائج التقرير بعد مغادرة السيارة من المركز
          </span>
        </div>
      </div>
    </div>
      <div
        class="contact hidden print:flex flex-col gap-3 font-bold print:absolute print:bottom-0 px-8 w-full text-xs"
      >
        <div class="qr-section flex items-center justify-end">
          <div class="qr-content p-1 rounded-md border-2">
            <qrcode :value="getQrValue" :options="{ width: 75 }"></qrcode>
          </div>
        </div>
        <div
          class="footer_info flex items-center justify-between border-t-2 py-3"
        >
          <span> بغداد - الحبيبية - ساحة الحمزة  </span>
          <span> 07850202060 - 07750202060 </span>
        </div>
      </div>
    </div>
    <div
      class="image-container print:h-screen relative"
      v-if="order.images.length !== 0"
    >
      <image-section
        :data="order"
        :hidden="true"
        sectionTitle="صور المركبة"
      ></image-section>
      <div
        class="contact hidden print:flex flex-col gap-3 font-bold print:absolute print:bottom-0 px-8 w-full text-xs"
      >
        <div class="qr-section flex items-center justify-end">
          <div class="qr-content p-1 rounded-md border-2">
            <qrcode :value="getQrValue" :options="{ width: 75 }"></qrcode>
          </div>
        </div>
        <div
          class="footer_info flex items-center justify-between border-t-2 py-3"
        >
          <span> بغداد - الحبيبية - ساحة الحمزة  </span>
          <span> 07850202060 - 07750202060 </span>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
@media print {
  .jeck_info {
    height: 1400px;
  }
  .order-container,
  .jek-container,
  .daino-container,
  .cover-container,
  .image-container {
    height: calc(100vh - 20px);
    margin-top: 10px;
    @apply border-4 border-gray-500;
    page-break-after: always;
  }
}

.water_mark {
  z-index: 9999;
}
</style>
<script>
// import orderInfo from "@/components/Print/OrderInfo.vue";
// import orederDetails from "@/components/Print/OrederDetails.vue";
import imageSection from "@/components/Print/ImageSection.vue";

export default {
  methods: {
    printPage() {
      window.print();
    },
  },
  computed: {
    getQrValue() {
      return `https://print.crc-almandelawi.com/${this.$route.params.id}`;
    },
  },
  components: {
    imageSection,
  },
  mounted() {
    this.getOrderMaintenance({
      id: this.$route.params.id,
      include: "images",
    })
  },
};
</script>
